import React from 'react'
import Intro from 'Components/clients/Intro'
import ClientTestimonials from 'Components/clients/ClientTestimonials'
import Awards from 'Components/common/Awards'
import Divider from 'Components/common/Divider'
import { graphql } from 'gatsby'
import Clients from 'Components/common/Clients'

export interface ClientType {
  [key: string]: {
    [key2: string]: string
  }
}

interface IProps {
  data: {
    stringsJson: {
      clients: {
        intro: ClientType
        quote: ClientType
        awards: {
          [key: string]: string
        }
        mobileApps: {
          [key2: string]: string
        }
        clients: {
          header: string,
          languages: {
            [key: string]: string
          },
          more: string
        }
      }
    }
  }
}

const ClientsPage: React.FC<IProps> = ({
  data: {
    stringsJson: { clients },
  },
}) => (
  <>
    <Intro intro={clients.intro} />
    <ClientTestimonials quote={clients.quote} />
    <Divider />
    <Awards awards={clients.awards} className="py-5" />
    <Clients headerId={clients.clients.header}
             usaId={clients.clients.languages.usa}
             ukId={clients.clients.languages.uk}
             noId={clients.clients.languages.no}
             moreId={clients.clients.more}
             className="py-5" />
  </>
)

export default ClientsPage

export const query = graphql`
  query NoClientQuery {
    stringsJson(clients: { lang: { eq: "no" } }) {
      ...Clients
    }
  }
`
